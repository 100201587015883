<template>
  <v-app>
    <v-container fluid class="white">
      <v-row>
        <div v-if="step == 1" class="mt-5 px-8">
          <!-- Información de la cuenta -->
          <v-col cols="12" md="4" class="d-flex justify-center">
            <p class="ma-0 pa-">
              Detectamos que tu cuenta está asociada a la cadena
              <span class="font-weight-bold">{{ employee.branch }}</span>
            </p>
          </v-col>

          <!-- Formulario -->
          <v-form v-model="validForm">
            <!-- Card 1: Mi Información -->
            <v-col cols="12" md="4" class="d-flex justify-center">
              <v-card
                class="pa-5 bgcards rounded-xl"
                elevation="2"
                style="width: 100%"
              >
                <v-card-title class="d-flex justify-start">
                  <v-icon class="primary--text mr-2" size="35px"
                    >mdi-account-outline</v-icon
                  >
                  <h3 class="text-h6 headline font-weight-bold text-start">
                    Tu información
                  </h3>
                </v-card-title>

                <v-card-text>
                  <div>
                    <pat-input
                      :disabled="true"
                      v-model="employee.name"
                      label="Nombre completo"
                      placeholder="Alonso Huete"
                    />
                    <br />
                    <p class="font-weight-bold black--text text-body-1 mb-2">
                      Sucursales asociadas
                    </p>
                    <div
                      v-for="(branch, index) in employee.associatedBranches"
                      :key="index"
                    >
                      <p class="ma-0">
                        <span class="font-weight-bold">{{
                          branch.branch
                        }}</span>
                        - {{ branch.position }}
                      </p>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>

            <!-- Botón Continuar -->
            <v-col cols="12" md="4" class="d-flex justify-center">
              <v-btn
                :disabled="!validForm"
                class="py-6"
                :color="validForm ? 'primary' : 'grey darken-2'"
                block
                rounded
                @click="proceed"
              >
                Continuar
              </v-btn>
            </v-col>
          </v-form>

          <!-- Información adicional -->
          <v-col cols="12" md="4" class="d-flex justify-center">
            <p class="ma-0 pa-">
              Si la información no es correcta, verifica el número de celular
              que ingresaste o llámanos.
            </p>
          </v-col>

          <!-- Botón Asistencia PAT -->
          <v-col cols="12" md="6">
            <v-btn
              rounded
              block
              outlined
              color="primary"
              class="py-6 border-3"
              style="border-width: 3px"
              @click="openPatAssistance"
            >
              <v-icon left>mdi-help-circle-outline</v-icon>
              Asistencia PAT
            </v-btn>
          </v-col>
        </div>
      </v-row>

      <!-- Modal de Código de verificación-->
      <verification-code
        v-if="step == 2"
        :rol="employee.rol"
        :pathName="'home'"
      />
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "create-account",
  data() {
    return {
      employee: {
        name: "Alonso Huete",
        branch: "La Divina",
        rol: 2,
        associatedBranches: [
          { branch: "La divina", position: "Vendedor" },
          { branch: "La divina sur", position: "Cajero" },
          { branch: "La divina norte", position: "Supervisor" },
        ],
      },
      validForm: false,
      step: 1,
      rules: {
        required: (value) => !!value || "Este campo es requerido.", // Regla de validación
      },
    };
  },
  methods: {
    //Método para continuar al siguiente paso.
    proceed() {
      if (this.validForm) {
        // Lógica de envío o redirección
        this.step = 2;
      } else {
        console.warn("El formulario no está completo.");
      }
    },

    openPatAssistance() {
      console.log("Abriendo la asistencia PAT...");
    },
  },
};
</script>
